import { createApp } from 'vue'
import { createPinia } from "pinia"
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { i18n } from './i18n'
import Vue3Lottie from 'vue3-lottie'
import utils from "@/utils/utils.js";
import App from './App.vue'
import './vee-validate'

createApp(App)
    .use(router)
    .use(i18n)
    .use(Vue3Lottie)
    .use(VueAxios, axios)
    .use(createPinia())
    .mount('#app')
