import {
  createRouter,
  createWebHistory
} from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: () =>
      import('@/views/home.vue'),
  },
  {
    path: '/aviso-legal',
    name: 'legalWarning',
    component: () =>
      import('@/views/legalWarning.vue'),
  },
  {
    path: '/politica-privacidad',
    name: 'privatePolicy',
    component: () =>
      import('@/views/privatePolicy.vue'),
  },
  {
    path: '/politica-cookies',
    name: 'cookiesPolicy',
    component: () =>
      import('@/views/cookiesPolicy.vue'),
  },
  {
    path: '/tus-datos-seguros',
    name: 'yourDataSafe',
    component: () =>
      import('@/views/yourDataSafe.vue'),
  }


 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0 }
  
  },
  routes
})

export default router
