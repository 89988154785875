<template lang="html">
  <section class="sub-footer container">
    <img src="/img/logo.svg" alt="logo">
    <div class="grid-links">
      <router-link  v-for="(item ,key) in $tm('menus.footer')" :key='key' :to="{name:key}">{{item}}</router-link>
    </div>
  </section>
</template>

<script lang="js">

  export default {
    name: 'footer'
  }


</script>

<style scoped lang="scss">
  .footer {}
</style>