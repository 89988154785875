<template>
  <div class="header">
    <div class="container">

      <router-link :to="{name:'home'}" class="logo-container">
        <img src="/img/logo.svg" alt="">
      </router-link>
      <div class="container-head" :showMenuFull="showMenuFull">
        <router-link v-for="(item ,key) in $tm('menus.header')" :key='key' to="/" @click="scrollSection(key)">{{item}}</router-link>
        <langSelector></langSelector>
        <button class="menu" @click="toggleShowMenuFull" :showMenuFull="showMenuFull">
          <svg viewBox="0 0 64 48">
            <path d="M19,15 L45,15 C70,15 58,-2 49.0177126,7 L19,37"></path>
            <path d="M19,24 L45,24 C61.2371586,24 57,49 41,33 L32,24"></path>
            <path d="M45,33 L19,33 C-8,33 6,-2 22,14 L45,37"></path>
          </svg>
        </button>
      </div>
    </div>

  </div>
</template>

<script>
  import langSelector from '@/components/langSelector';
  export default {
    name: 'headerWorld',
    props: {
      msg: String
    },
    components: {
      langSelector
    },
    data() {
      return {
        showMenuFull: false,
      }
    },
    methods: {
      toggleShowMenuFull: function () {
        this.showMenuFull = !this.showMenuFull;
      },
      scrollSection: function (params) {
        this.showMenuFull = false
        setTimeout(function () {
          document.getElementById(params).scrollIntoView({
            behavior: "smooth",
            //behavior: "auto",
            block: "start"
          });
        }, 200);

      }
    },
    watch: {
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>