<template>
  <section class="lang-selector">
    <button @click="changeLang('es')" :active="this.$i18n.locale == 'es'  ? true : false">Es</button>
    <button @click="changeLang('en')" :active="this.$i18n.locale == 'en'  ? true : false">En</button>
  </section>
</template>
<script>
  import { i18n, setI18nLanguage } from '@/i18n'
  export default {
    name: 'App',
    methods: {
      changeLang(lang) {
        setI18nLanguage(i18n, lang);
      },
    },
   
  }
</script>